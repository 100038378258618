import React, { useState, useEffect } from "react"
import Layout from '../components/layout'
import { graphql } from "gatsby";
import "normalize.css"
import scrollTo from 'gatsby-plugin-smoothscroll';
import {
  listItemTitle,
  upArrow,
  listItemCursor,
  img,
  tweet, timestamp, word, aday
} from '../components/layout.module.css'

import Typewriter from 'typewriter-effect';
import GraphemeSplitter from "grapheme-splitter";
import { grabLinkFromText, findInsertPoint} from '../helper.js';

import './project.scss'

const IndexPage = ( { data } ) => {
  const stringSplitter = string => {
    const splitter = new GraphemeSplitter();
    return splitter.splitGraphemes(string);
  };

  const prepareRender = biu => {
      switch (biu.type) {
        case "poems": {
          const poem = JSON.parse(biu.content);
          let typeString = '';
          for (const line in poem) {
            typeString += `.typeString("<br />.typeString("${line}").pauseFor(${poem[line]}")`
          }
          return <div key={biu.id} className={aday}>
      <div className={tweet}>
        <span className={timestamp}>{new Date(biu.createdAt).toLocaleTimeString('en-US') + ' ' + Intl.DateTimeFormat().resolvedOptions().timeZone}</span>
          <Typewriter
                          options={{skipAddStyles:true, 
                            wrapperClassName:word,
                            cursorClassName: listItemCursor, 
                            delay: 65,
                            stringSplitter}}
            onInit={(typewriter) => {
              typewriter.typeString(`<strong> ${biu.title} </strong>`)
              .pauseFor(3000)
                .start();
              for (const line in poem) {
                typewriter.typeString("<br />").typeString(line).pauseFor(poem[line])
              }
            }}
          />
      </div>
    </div>
        }
        case "visuals": {
          return <div key={biu.id} className={aday}>
          <div className={tweet}>
            <span className={timestamp}>{new Date(biu.createdAt).toLocaleTimeString('en-US') + ' ' + Intl.DateTimeFormat().resolvedOptions().timeZone}</span>
              <div>
                <div><img className={img} src={biu.content} alt="" /></div>
                <div className={listItemTitle}>{biu.title}</div>
              </div>
          </div>
        </div>
        }
        case "words": {
          const link = grabLinkFromText(biu.content)
          const insertionPoint = link && findInsertPoint(biu.content)
          const content1 = link && biu.content.slice(0, insertionPoint)
          const content2 = link && biu.content.slice(insertionPoint + link[0].length)
          return <div key={biu.id} className={aday}>
              <div className={tweet}>
                  <span className={timestamp}>{new Date(biu.createdAt).toLocaleTimeString('en-US') + ' ' + Intl.DateTimeFormat().resolvedOptions().timeZone}</span>
                  {link && <div className={word}>{content1}<a href={link[0]} target="_blank" rel="noopener noreferrer">{link[0]}</a>{content2}</div>|| <div className={word}>{biu.content}</div>}
            </div>
          </div>
        }
        default: break;
      }
  }
  
  return (
    <Layout pageTitle="biuble">
      <div data-sal="fade"
            data-sal-delay="300"
            data-sal-duration="500"
            data-sal-easing="ease">
          {data.postgres.bius.sort((a,b) => b.id - a.id).reverse().map(biu => prepareRender(biu))}
        <button className={upArrow} 
            data-sal="fade"
            data-sal-delay="300"
            data-sal-duration="500"
            data-sal-easing="ease" onClick={() => scrollTo('#blog')}>↑</button>
      </div>
    </Layout>
  )

  
}


export const query = graphql`
  {
    postgres {
      bius : allBiusesList {
        id
        type
        title
        content
        createdAt
      }
      comments : allCommentsList {
        id
        name
        content
        createdAt
      }
    }
  }
`;

export default IndexPage